h1 {
  margin: 0;
  padding: 0;
}
h2 {
  margin: 0;
  padding: 0;
}
h3 {
  margin: 0;
  padding: 0;
}
h4 {
  margin: 0;
  padding: 0;
}
h5 {
  margin: 0;
  padding: 0;
}

* {
  font-size: 1.5rem;
}